import {EventEmitter, Inject, Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {ActivatedRoute} from "@angular/router";
import {SESSION_STORAGE, StorageService} from "ngx-webstorage-service";

export interface Product {
  "id": string; //"prod_QDqcFWV7ln47vf",
  "object": string; // "product",
  "active": boolean; // true,
  "attributes": any[],
  "created": number; // 1717375158,
  "default_price": string; // "price_1PNOvj09u5A5II2MxtlLO0yS",
  "description": string; // "Quickly calculate VRT, customs' duty and transport costs.",
  "images": any[],
  "livemode": boolean; //false,
  "marketing_features": any[],
  "metadata": { [key: string]: any },
  "name": string; //"Calculate Cars Quicker - test",
  "package_dimensions": null,
  "shippable": null,
  "statement_descriptor": null,
  "tax_code": string; //"txcd_10000000",
  "type": string; //"service",
  "unit_label": string // null,
  "updated": number; //1717375159,
  "url": string; // null
  price: number; // 19900
  currency: "EUR" | "GBP"; // EUR | GBP
  "checked"?: boolean;
}

@Injectable({
  providedIn: "root"
})
export class CommerceService {

  public products: Product[] | undefined;
  public gotProds: EventEmitter<Product[]> = new EventEmitter<Product[]>();
  constructor(private apiService: ApiService, route: ActivatedRoute, @Inject(SESSION_STORAGE) storage: StorageService) {
    // Get products
    this.getProds().then(() => {
      // Check if we need to swap out TCS Pro in this session
      let tcs = route.snapshot.queryParams["tcs"];
        if (tcs) {
          storage.set("tcs", tcs);
          this.updateTcs(tcs);
        } else {
          tcs = storage.get("tcs");
          if (tcs) {
            this.updateTcs(tcs);
          }
        }
        this.gotProds.emit(this.products);
    });
  }

  /**
   * Retrieve the product for additional credits
   */
  public creditProduct(): Product | undefined {
    return this.products?.find(p => {
      return p.metadata["service"] == "credit";
    });
  }

  /**
   * Get products in sorted order. Using metadata order for sorting
   */
  public get products_sorted(): Product[] | undefined {
    return this.products?.filter(p => {
      return p.metadata["visible"] !== 0;
    }).sort((a, b) => {
      return a["metadata"]["order"] - b["metadata"]["order"];
    });
  }

  /**
   * Return products selectd in the UI
   */
  public get selectedProducts(): Product[] {
    if (!this.products) {
      return [];
    }
    return this.products?.filter(p => p.checked)
  }

  /**
   * Return the default product
   */
  public get defaultProduct(): Product | undefined {
    return this.products?.find(p => p.metadata["default"]);
  }

  async getProds() {
    const res = await this.apiService.getProducts();
    this.products = res.data.data;
    // Set the default product and check it
    if (this.defaultProduct) {
      this.defaultProduct.checked = true;
    }
  }

  reset() {
    if (this.products) {
      for (let p of this.products) {
        p.checked = false;
      }
    }
  }

  selectProd(prod: Product) {
    // Uncheck all products
    for (let p of this.products ?? []) {
      p.checked = false;
    }
    // Toggle check only for this product
    prod.checked = !prod.checked;

    // Check if we need to uncheck the default product
    // if (prod.checked && this.defaultProduct && prod !== this.defaultProduct) {
    //   this.defaultProduct.checked = false;
    // } else if (prod == this.defaultProduct && this.products) {
    //   // Uncheck all other prods
    //   for (let p of this.products) {
    //     if (p == this.defaultProduct) {
    //       continue;
    //     }
    //     p.checked = false;
    //   }
    // }
  }

  private setDefaultProduct(p: Product): void {
    p.metadata["default"] = 1;
    p.metadata["visible"] = 1;
    this.selectProd(p);
  }

  private updateTcs(tcs: string): void {
    if (!this.products) {
      return;
    }

    // Unset the current default prod
    this.unsetDefaultProd();

    for (let p of this.products) {
      // We need to swap out TCS Pro for an alternative version
      if (p.name == "TCS Enterprise Plus" && tcs === "enterpriseplus") {
        this.setDefaultProduct(p);
        return;
      } else if (p.name == "TCS Enterprise" && tcs === "enterprise") {
        this.setDefaultProduct(p);
        return;
      } else if (p.name == "TCS Light" && tcs === "light") {
        this.setDefaultProduct(p);
        return;
      } else if (p.name == "TCS Mini" && tcs === "mini") {
        this.setDefaultProduct(p);
        return;
      } else if (p.name == "TCS Pro" && (!tcs || tcs === "pro")) {
        this.setDefaultProduct(p);
        return;
      }
    }
  }

  private unsetDefaultProd() {
    if (!this.products) {
      return;
    }
    for (let p of this.products) {
      p.metadata["default"] = 0;
      if (p.name.startsWith("TCS")) {
        p.metadata["visible"] = 0;
      }
    }
  }

  public static prodName(service: string | undefined): string {
    switch (service) {
      case "ccq":
        return "Calculate Cars Quicker";
      case "fcf":
        return "Find Cars Faster";
      case "cyc":
        return "Check Your Car";
      case "ffl":
        return "Forex For Less";
      default:
        return service ?? "The CarSite";
    }
  }
}
